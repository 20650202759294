@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/Sarabun-Thin.ttf');
  src: local('Sarabun Thin'),
    url('../fonts/Sarabun-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/Sarabun-Light.ttf');
  src: local('Sarabun Light'),
    url('../fonts/Sarabun-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/Sarabun-Regular.ttf');
  src: local('Sarabun Text'),
    url('../fonts/Sarabun-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/Sarabun-Medium.ttf');
  src: local('Sarabun Medium'),
    url('../fonts/Sarabun-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/Sarabun-SemiBold.ttf');
  src: local('Sarabun Semi Bold'),
    url('../fonts/Sarabun-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/Sarabun-Bold.ttf');
  src: local('Sarabun Bold'),
    url('../fonts/Sarabun-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/Sarabun-ExtraBold.ttf');
  src: local('Sarabun Bold'),
    url('../fonts/Sarabun-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
